(window as any).global = window;

import '@angular/localize/init';

import { ZEF } from '@shared/models/zef.model';

import 'zone.js';

import '@webcomponents/custom-elements/src/native-shim';
import '@webcomponents/custom-elements/custom-elements.min';

declare global {
  interface Window {
    ZEF?: ZEF;
    global: Window;
    HubSpotConversations: any;
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
  }
}
